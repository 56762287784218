import React, { useEffect } from 'react'
import { navigate as gatsbyNavigate } from 'gatsby'
import detectLocale from './detectLocale'

const Redirect = ({ navigate = gatsbyNavigate, pageContext }) => {
  useEffect(() => {
    const detected = detectLocale()
    const newUrl = `/${detected}${pageContext && pageContext.redirectPage}`

    localStorage.setItem('language', detected)
    if (pageContext.redirectPage === '/dev-404-page/' || pageContext.redirectPage === '/404.html') {
      navigate('/')
    } else {
      navigate(newUrl)
    }
  }, [])

  return <div />
}

export default Redirect
